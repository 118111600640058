body {
  margin: 0;
  font-weight: 300;
  line-height: 1.9;
  font-size: 0.825rem;
  letter-spacing: 0.0375rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.tcm-icon {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 10px;
}
.text-white,
.contact .ant-row .left .ant-typography {
  color: white !important;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.opacity-75 {
  opacity: 0.75;
}
.font-bold {
  font-weight: bold;
}
.font-14px {
  font-size: 14px !important;
}
.font-16px {
  font-size: 16px !important;
}
.font-22px {
  font-size: 22px !important;
}
.font-34px {
  font-size: 34px !important;
}
.d-block {
  display: block !important;
}
.d-flex {
  display: flex !important;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.column-reverse {
  flex-direction: column-reverse;
}
.flex-1 {
  flex: 1 !important;
}
.m-auto {
  margin: auto !important;
}
.m-0 {
  margin: 0 !important;
}
.m-1 {
  margin: 0.5rem !important;
}
.m-2 {
  margin: 1rem !important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.mr-2 {
  margin-right: 1rem !important;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.ml-2 {
  margin-left: 1rem !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 1rem !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.pt-1 {
  padding-top: 0.5rem !important;
}
.pt-2 {
  padding-top: 1rem !important;
}
.pt-3 {
  padding-top: 1.5rem !important;
}
.pl-3 {
  padding-left: 1.5rem !important;
}
.pr-3 {
  padding-right: 1.5rem !important;
}
.p-0 {
  padding: 0 !important;
}
.p-1 {
  padding: 0.5rem !important;
}
.p-2 {
  padding: 1rem !important;
}
.p-3 {
  padding: 1.5rem !important;
}
.pb-1 {
  padding-bottom: 0.5rem !important;
}
.bg-white {
  background-color: white !important;
}
.h-100 {
  height: 100%;
}
.w-auto {
  width: auto;
}
.w-50 {
  width: 50%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.gap-2 {
  gap: 1rem;
}
img {
  max-width: 100% !important;
}
.title {
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(45deg, #09009f, #00ff95 80%);
}
.ant-layout-header {
  top: 0;
  z-index: 111;
  position: sticky;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
  border: transparent;
}
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-item,
.ant-layout-footer,
.ant-descriptions-item-container,
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-label div {
  display: flex;
  align-items: center;
}
.accomplishments .ant-statistic,
a.ant-typography.d-block,
.hobbies {
  text-align: center;
  border-radius: 0.25rem;
  padding: 1rem !important;
  border: 1px solid rgba(0, 0, 0, 0.125);
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.accomplishments .ant-statistic:hover,
.hobbies:hover,
.clients a.ant-typography.d-block:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.accomplishments .ant-space-item .ant-statistic-title {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
}
.accomplishments .ant-space-item .ant-statistic-content {
  font-size: 28px;
  font-weight: bold;
}
img.ant-image-img.client-logo {
  width: 96px;
  height: 96px;
  align-items: center;
  text-align: center;
  display: flex;
}
.ant-list-header {
  font-size: 18px;
  font-weight: bold;
  padding: 0 20px 12px;
}

.skills .ant-descriptions-row > th,
.skills .ant-descriptions-row > td {
  padding: 1rem !important;
  padding-left: 0 !important;
}
.skills .ant-descriptions-item-label {
  width: 25%;
  max-width: 200px;
}
.testimonials .client {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 340px;
  width: 340px;
  background: url(./images/greadient-bg.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.testimonials .ant-carousel .slick-dots li button {
  background: #5ad4b0 !important;
}
.posts .ant-spin-nested-loading {
  height: calc(100% - 100px);
}
.posts .ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
}
.contact > .ant-row {
  border: 1px solid #f5f5f5;
  border-radius: 6px;
  max-width: 60%;
  margin: 1rem auto !important;
  box-shadow: 0 0 5px rgb(0 0 0 / 10%);
}
.contact > .ant-row .left {
  position: relative;
  border-radius: 5px 0 0 5px;
  background: linear-gradient(45deg, #2196f3, #8bc34a);
}
.contact > .ant-row .left .bottom-navs {
  position: absolute;
  bottom: 1rem;
}
.contact > .ant-row .left .bottom-navs a.ant-typography {
  font-size: 26px;
}
.ant-list.portfolio-list .ant-list-items {
  gap: 1rem;
  display: flex;
  flex-wrap: wrap;
}
.ant-list.portfolio-list li.ant-list-item {
  padding: 0;
  border-radius: 6px;
  border: 1px solid #f3e9e9;
  max-width: calc(25% - 0.75rem);
  transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
}
.ant-list.portfolio-list li.ant-list-item .ant-list-item-meta {
  align-items: center;
}
.ant-list.portfolio-list .ant-list-item-meta-avatar {
  margin-right: 0;
  text-align: center;
  margin-left: 0.75rem;
}
.ant-list.portfolio-list .ant-list-item-meta-content {
  width: 100%;
  padding: 0.75rem;
}
.ant-list.portfolio-list li.ant-list-item:hover {
  background: #e2fcff;
}
.ant-list-item-meta-description {
  color: #212121;
  font-weight: 400;
}
.ant-list.portfolio-list .ant-ribbon.ant-ribbon-placement-end {
  top: -8px;
}

@media screen and (max-width: 720px) {
  .ant-col {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .ant-layout-header {
    padding-right: 0 !important;
    padding-left: 1rem !important;
  }
  .ant-layout-header h5 {
    margin-left: 0.5rem !important;
  }
  .ant-menu-submenu-popup li.ant-menu-item a.ant-btn {
    display: flex;
    align-items: center;
    padding-bottom: 0 !important;
  }
  .ant-breadcrumb {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  section.ant-layout {
    padding: 0 !important;
  }
  .ant-layout-sider {
    position: fixed;
    height: 100vh;
    z-index: 111;
    left: 0;
    flex: 0 0 60px !important;
    max-width: 60px !important;
    min-width: 60px !important;
    width: 60px !important;
  }
  .ant-layout-sider .ant-menu-root.ant-menu-vertical {
    width: 100% !important;
    padding-top: 0 !important;
  }
  footer.ant-layout-footer {
    z-index: 999;
    padding: 1rem;
    display: block;
    text-align: center;
  }
  .accomplishments .ant-statistic,
  a.ant-typography.d-block,
  .hobbies {
    /* border: 0 !important; */
    padding: 0.5rem !important;
  }
  .hobbies .anticon,
  .accomplishments .ant-space-item .ant-statistic-content,
  .ant-carousel .slick-list .slick-slide > div .header h1.ant-typography,
  .contact > .ant-row .left h3.ant-typography {
    font-size: 22px !important;
  }
  .hobbies .ant-typography,
  .accomplishments .ant-space-item .ant-statistic-title {
    font-size: 1rem !important;
  }
  .skills .ant-descriptions-item-label {
    width: 55% !important;
  }
  .ant-carousel .slick-list .slick-slide > div {
    width: 100% !important;
  }
  .ant-carousel .slick-list .slick-slide > div .header {
    padding: 1rem !important;
  }
  .ant-carousel .slick-list .slick-slide > div .footer {
    padding: 4.5rem 1rem 1rem !important;
  }
  .ant-carousel .slick-list .slick-slide > div .header .client {
    left: calc(50% - 40px - 0.5rem);
    bottom: -70px;
  }
  .contact > .ant-row {
    max-width: 100%;
  }
  .contact > .ant-row .left,
  .contact > .ant-row .right {
    padding: 1rem !important;
    border-radius: 5px 5px 0 0 !important;
  }
  .contact > .ant-row .left .bottom-navs a.ant-typography {
    font-size: 20px;
  }
  .contact > .ant-row .left h4.ant-typography.ccl,
  .contact > .ant-row .left h4.ant-typography.ccl .anticon {
    font-size: 14px !important;
  }
  .contact > .ant-row .left .bottom-navs {
    position: static;
  }
  .posts.ant-list-lg .ant-list-item {
    padding: 0 0 1rem !important;
  }
  .cats .ant-space {
    flex-wrap: wrap;
    font-weight: bold;
  }
  .top-menu {
    flex-direction: column !important;
  }
  .portfolio > .ant-typography {
    gap: 0.5rem;
    margin-bottom: 1rem;
    flex-direction: column;
  }
  .ant-list.portfolio-list li.ant-list-item {
    max-width: 100% !important;
  }
  .ant-list.portfolio-list .ant-ribbon.ant-ribbon-placement-end {
    right: 0;
  }
  .testimonials .client {
    height: 132px;
    width: 132px;
  }
}
